<template>
  <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
    <div v-for="template in randomTemplates" :key="template.title" @click="addUserMessage(template.question)" class="cursor-pointer rounded-xl border p-1 hover:scale-105 sm:p-2">
      <FontAwesomeIcon :icon="template.icon" />
      <p class="text-center text-xs text-primary-800 sm:mt-2 sm:text-sm md:text-base">{{ template.title }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const templates = [
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.SetPNOSettingsHeader'),
    question: t('aiassistant.SetPNOSettingsDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.AdFormatsHeader'),
    question: t('aiassistant.AdFormatsDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.PrepareMaterialsHeader'),
    question: t('aiassistant.PrepareMaterialsDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.ImageDimensionsHeader'),
    question: t('aiassistant.ImageDimensionsDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.EvaluateResultsHeader'),
    question: t('aiassistant.EvaluateResultsDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.LearningPhaseHeader'),
    question: t('aiassistant.LearningPhaseDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.ShopSetupCheckHeader'),
    question: t('aiassistant.ShopSetupCheckDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.TanganicaPixelHeader'),
    question: t('aiassistant.TanganicaPixelDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.ProductStatusHeader'),
    question: t('aiassistant.ProductStatusDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.IncreaseTrafficHeader'),
    question: t('aiassistant.IncreaseTrafficDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.OptimizeBudgetHeader'),
    question: t('aiassistant.OptimizeBudgetDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.SocialMediaHeader'),
    question: t('aiassistant.SocialMediaDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.ImproveSEOHeader'),
    question: t('aiassistant.ImproveSEODescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.EmailMarketingHeader'),
    question: t('aiassistant.EmailMarketingDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.EcommerceTrendsHeader'),
    question: t('aiassistant.EcommerceTrendsDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.OptimizeProductPagesHeader'),
    question: t('aiassistant.OptimizeProductPagesDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.IncreaseConversionRateHeader'),
    question: t('aiassistant.IncreaseConversionRateDescription'),
  },
  {
    icon: 'fa-user-circle',
    title: t('aiassistant.IncreaseConversionRatioHeader'),
    question: t('aiassistant.IncreaseConversionRatioDescription'),
  },
];

const emits = defineEmits(['onAddUserMessage']);

// Get random 4 items from templates
const randomTemplates = templates.sort(() => Math.random() - 0.5).slice(0, 4);

const addUserMessage = (message: string) => {
  emits('onAddUserMessage', message);
};
</script>
