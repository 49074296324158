<template>
  <div id="wrapper">
    <div class="layout-topbar justify-between">
      <!-- DESKTOP -->
      <div class="hidden items-center lg:flex">
        <img :src="$filePath + '/logos/tanganicaLogoWhite.png'" alt="logo" class="h-full max-h-[2.5rem] w-auto object-cover object-left xl:max-h-[2.5rem]" />
      </div>
      <div class="w-max-content ml-24 hidden h-[2.5rem] w-[14.5rem] items-center justify-center rounded-md bg-white px-6 lg:flex">
        <FontAwesomeIcon class="text-primary-900" icon="fa-sack-dollar"></FontAwesomeIcon>
        <div class="flex w-full py-1.5 pl-2 text-center font-bold text-primary-900">124 000 Kč</div>
      </div>
      <div class="mx-auto hidden h-fit w-full justify-center rounded-xl text-lg lg:flex">
        <SelectEshop class="max-w-[28rem]"></SelectEshop>
        <Button class="ml-2 bg-white px-6 text-primary-900 hover:opacity-90" type="submit" :label="'Přidat'">
          <template #icon>
            <FontAwesomeIcon class="text-primary-900" icon="fa-plus"></FontAwesomeIcon>
          </template>
        </Button>
      </div>
      <div class="hidden cursor-pointer items-center text-white hover:opacity-50 lg:flex" @click="toggleTopMenu">
        <FontAwesomeIcon icon="fa-user-circle" size="2xl"></FontAwesomeIcon>
      </div>
      <!-- MOBILE -->
      <div class="flex w-full items-center justify-between gap-5 lg:hidden">
        <div class="cursor-pointer items-center text-white hover:opacity-50" @click="toggleSideMenu">
          <FontAwesomeIcon icon="fa-bars" size="xl"></FontAwesomeIcon>
        </div>
        <div class="flex items-center rounded-lg px-2 py-1.5">
          <img :src="$filePath + '/logos/tanganicaLogoWhite.png'" alt="logo" class="h-full w-28 object-cover object-left" />
        </div>
        <div class="cursor-pointer items-center text-white hover:opacity-50" @click="toggleTopMenu">
          <FontAwesomeIcon icon="fa-user-circle" size="2xl"></FontAwesomeIcon>
        </div>
      </div>
      <div class="mt-4 h-fit w-full items-center justify-between gap-6 bg-transparent text-sm sm:flex lg:hidden">
        <div class="flex h-[2.5rem] w-fit items-center rounded-md bg-white px-4 lg:hidden">
          <FontAwesomeIcon class="text-primary-900" icon="fa-sack-dollar"></FontAwesomeIcon>
          <div class="flex pl-2 text-center text-sm font-bold text-primary-900">124 000 Kč</div>
        </div>
        <div class="mt-2 flex h-fit w-fit items-center rounded-xl text-lg sm:mt-0 sm:w-[30rem] lg:hidden">
          <SelectEshop class="!sm:text-sm sm:max-w-[28rem]"></SelectEshop>
          <FontAwesomeIcon class="ml-2 cursor-pointer text-white" icon="fa-plus" size="lg"></FontAwesomeIcon>
        </div>
      </div>
      <Menu
        ref="topMenu"
        id="overlay_menu"
        :model="topMenuItems"
        :popup="true"
        class="m-0 min-w-10 border-none bg-gray-50 px-2"
        :ptOptions="{ mergeProps: true }"
        :pt="{ item: 'w-full hover:bg-main !p-0 !m-O', itemLink: 'p-0 m-0 gap-0', itemContent: 'p-0 gap-0' }"
      >
        <template #item="{ item, props }">
          <router-link v-slot="{ href, navigate, isActive }" :to="{ name: item.route }" custom>
            <a :href="href" v-bind="props.action" @click="navigate" :class="[isActive ? 'font-bold text-primary-900' : 'text-primary-900', 'w-42 ml-0 flex rounded-sm py-0.5 pl-2 hover:opacity-70']">
              <FontAwesomeIcon :icon="item.icon" size="lg"></FontAwesomeIcon>
              <span class="ml-2">{{ item.label }}</span>
            </a>
          </router-link>
        </template>
      </Menu>
    </div>
    <div id="main">
      <div :class="['layout-sidebar w-[13rem] pt-4 transition-all duration-300', showMenu ? 'transform-none' : '-translate-x-full']">
        <div class="">
          <!-- SHOW AND HIDE MENNU ARROWS -->
          <div :class="['absolute z-10 hidden cursor-pointer rounded-md bg-gray-500 px-2 py-1 hover:opacity-80 lg:block', showMenu ? '-right-2 bottom-1' : '-right-10 bottom-1']" @click="toggleSideMenu">
            <FontAwesomeIcon v-if="showMenu" icon="fa-arrow-left" class="text-white"></FontAwesomeIcon>
            <FontAwesomeIcon v-else icon="fa-arrow-right" class="text-white"></FontAwesomeIcon>
          </div>
          <!-- TANGANICA PREMIUM -->
          <div class="absolute bottom-10 left-1/2 z-10 w-full -translate-x-1/2 cursor-pointer rounded-xl bg-transparent px-4 text-center">
            <img :src="$filePath + '/icons/premium.svg'" class="absolute -top-4 left-1/2 w-8 -translate-x-1/2 rounded-full bg-primary-900 text-white" />
            <div class="h-full w-full rounded-xl bg-gray-50 p-2 py-4 shadow">
              <!-- <FontAwesomeIcon icon="fa fa-regular fa-gem" class="absolute -top-5 left-1/2 w-fit -translate-x-1/2 rounded-full bg-primary-900 p-3 text-white" size="xl"></FontAwesomeIcon>-->
              <span class="text-xs font-bold text-gray-700">Upgrade to <span class="block text-sm text-highlight-900">Tanganica Premium </span>for more funcionality</span>
              <Button class="mt-5 w-full text-xs font-bold" type="submit" severity="contrast" :label="'Upgrade'" />
            </div>
          </div>
          <PanelMenu
            :model="items"
            class="bgtransparent m-0 w-full min-w-10 border-none pl-2 pt-5"
            :ptOptions="{ mergeProps: true }"
            :pt="{ panel: 'w-full bg-transparent !m-O border-none py-0', item: 'mx-0', itemLink: 'py-0 m-0 gap-0', itemContent: 'p-0 gap-0 hover:bg-transparent', headerContent: 'p-0 gap-0 hover:bg-primary-900' }"
          >
            <template #item="{ item, props, hasSubmenu, active }">
              <router-link v-slot="{ href, navigate, isActive }" :to="{ name: item.route }" custom>
                <hr v-if="item.id === 6" class="mb-2 h-0.5 bg-primary-900" />
                <a
                  :href="href"
                  v-bind="props.action"
                  @click="navigate"
                  :class="[
                    isActive && item.route ? ' bg-gray-100 text-primary-900' : 'bg-transparent text-white',
                    'relative my-0.5 -mr-1 ml-0 flex min-w-full items-center rounded-md rounded-r-none px-2 py-0.5 hover:bg-gray-100 hover:text-primary-900',
                  ]"
                >
                  <FontAwesomeIcon :icon="item.icon" size="sm" class=""></FontAwesomeIcon>
                  <span class="ml-1">{{ item.label }}</span>
                  <span v-if="hasSubmenu" class="ml-auto mr-1">
                    <FontAwesomeIcon v-if="!active" icon="fa-solid fa-chevron-down" class="text-primary-900" size="sm"></FontAwesomeIcon>
                    <FontAwesomeIcon v-else icon="fa-solid fa-chevron-up" class="text-primary-900" size="sm"></FontAwesomeIcon>
                  </span>
                  <img v-if="item.premium" :src="$filePath + '/icons/premium.svg'" class="ml-auto mr-0" />
                  <div v-if="isActive" class="menu-item-before"></div>
                  <div v-if="isActive" class="menu-item-after"></div>
                </a>
              </router-link>
            </template>
          </PanelMenu>
        </div>
      </div>
      <div :class="['layout-content-wrapper bg-gr transition-all duration-300', showMenu ? 'lg:ml-[13rem]' : 'm-0']">
        <div class="layout-content h-full bg-gray-100">
          <RouterView v-slot="{ Component }">
            <template v-if="Component">
              <Suspense timeout="0">
                <component :is="Component"></component>
                <template #fallback>
                  <Loading></Loading>
                </template>
              </Suspense>
            </template>
          </RouterView>
        </div>
      </div>
    </div>
  </div>
  <PremiumModal></PremiumModal>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'; // importujeme useWindowSize
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import PanelMenu from 'primevue/panelmenu';
import { ref, watch } from 'vue';
import { RouterView, useRouter } from 'vue-router';
import { ACCOUNT_ROUTES } from '@/modules/account/enums/RoutesEnum';
import { AI_ASSISTANT_ROUTES } from '@/modules/aiAssistant/enums/RoutesEnum';
import { AI_CONTENT_ROUTES } from '@/modules/aiContent/enums/RoutesEnum';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { CREDIT_ROUTES } from '@/modules/credit/enums/RoutesEnum';
import { DASHBOARD_ROUTES } from '@/modules/dashboard/enums/RoutesEnum';
import { ESHOP_SETTINGS_ROUTES } from '@/modules/eshopSettings/enums/RoutesEnum';
import Loading from '@/modules/shared/components/Loading.vue';
import PremiumModal from '@/modules/shared/components/PremiumModal.vue';
import SelectEshop from '@/modules/shared/components/SelectEshop.vue';
import { STATISTIC_ROUTES } from '@/modules/statistic/enums/RoutesEnum';
import { WIZARD_ROUTES } from '@/modules/wizard/enums/RoutesEnum';
import { WIZARD_ON_BOARDING_ROUTES } from '@/modules/wizardOnBoarding/enums/RoutesEnum';

const topMenu = ref(null);

const router = useRouter();
const showMenu = ref(true);

const { width: windowWidth } = useWindowSize();

const items = ref([
  { label: 'Dashboard', icon: 'fa-home', route: DASHBOARD_ROUTES.INDEX },
  {
    label: 'Statistic',
    icon: 'fa-gear',
    route: STATISTIC_ROUTES.INDEX,
    id: 0,
  },
  {
    label: 'Campaign',
    icon: 'fa-table-columns',
    route: CAMPAIGNS_ROUTES.INDEX,
    premium: false,
    id: 1,
  },
  {
    label: 'Credit',
    icon: 'fa-credit-card',
    route: CREDIT_ROUTES.INDEX,
    premium: false,
    id: 2,
  },
  {
    label: 'Product',
    icon: 'fa-gamepad',
    route: STATISTIC_ROUTES.PRODUCTS_STATISTICS_PAGE,
    premium: false,
    id: 3,
  },
  {
    label: 'Content',
    icon: 'fa-table-columns',
    route: AI_CONTENT_ROUTES.INDEX,
    premium: true,
    id: 4,
  },
  {
    label: 'Ai Assistant',
    icon: 'fa-wand-sparkles',
    route: AI_ASSISTANT_ROUTES.INDEX,
    premium: true,
    id: 5,
  },
  {
    label: 'Settings',
    icon: 'fa-gears',
    route: ESHOP_SETTINGS_ROUTES.INDEX,
    premium: false,
    id: 6,
    /* items: [
      {
        label: 'Eshop',
        icon: 'fa-wand-sparkles',
        route: WIZARD_ROUTES.ESHOP,
      },
      {
        label: 'Country',
        icon: 'fa-wand-sparkles',
        route: WIZARD_ROUTES.COUNTRY,
      },
      {
        label: 'Phone',
        icon: 'fa-wand-sparkles',
        route: WIZARD_ROUTES.PHONE,
      },
    ],*/
  },
  {
    label: 'Affiliate Program',
    icon: 'fa-home',
    route: ACCOUNT_ROUTES.LOGOUT,
    premium: false,
    id: 7,
  },
  {
    label: 'Help',
    icon: 'fa-question-circle',
    route: ACCOUNT_ROUTES.LOGOUT,
    premium: false,
    id: 8,
  },
  {
    label: 'Logout',
    icon: 'fa-home',
    route: WIZARD_ROUTES.COUNTRY,
    premium: false,
    id: 9,
  },
  {
    label: 'Test',
    icon: 'fa-home',
    route: WIZARD_ON_BOARDING_ROUTES.INDEX,
    id: 9,
  },
]);

const topMenuItems = ref([
  // { label: 'Profile', icon: 'pi pi-user', route: ACCOUNT_ROUTES.PROFILE },
  { label: 'Settings', icon: 'fa-gears', route: ACCOUNT_ROUTES.SETTINGS },
  { label: 'Logout', icon: 'fa-right-from-bracket', route: ACCOUNT_ROUTES.LOGOUT },
]);

watch(windowWidth, (newWidth) => {
  showMenu.value = newWidth >= 1024;
});

const toggleSideMenu = () => {
  showMenu.value = !showMenu.value;
};

const toggleTopMenu = (event) => {
  topMenu.value.toggle(event);
};
</script>

<style lang="css" scoped>
#wrapper {
  width: 100%;
}

.layout-topbar {
  height: 5rem;
  padding: 0 2rem 0 1rem;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  @apply bg-gradient-to-r from-primary-700 from-30% to-primary-500;
}

#main {
  display: flex;
  margin-top: 5rem;
}

.layout-sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: calc(100% - 5rem);
  left: 0;
  top: 5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  @apply bg-gradient-to-b from-primary-700  to-primary-500;
  z-index: 999;
}

.layout-content-wrapper {
  flex: 1;
  height: calc(100vh - 5rem);
  overflow: hidden;
  @apply bg-primary-700;
}

.layout-content {
  height: calc(100vh - 5rem);
  overflow-y: auto;
  padding: 2rem;
  background-color: var(--surface-ground);
  border-top-left-radius: 0.75rem;
  box-shadow: inset 0px 0px 0px #0000001a;

  @apply bg-gray-100;
}

@media (max-width: 1024px) {
  .layout-topbar {
    height: fit-content;
    padding: 1rem 1rem 1rem 1rem;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: block;
    align-items: center;
    @apply bg-primary-900;
  }

  #main {
    display: flex;
    margin-top: 7.9rem;
  }

  .layout-sidebar {
    height: calc(100% - 7.9rem);
    top: 7.9rem;
  }
}

@media (max-width: 640px) {
  .layout-topbar {
    height: fit-content;
    padding: 1rem 1rem 1rem 1rem;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: block;
    align-items: center;
    @apply bg-primary-900;
  }

  #main {
    display: flex;
    margin-top: 10rem;
  }

  .layout-sidebar {
    height: calc(100% - 7.9rem);
    top: 10rem;
  }
}
</style>
