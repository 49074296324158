<template>
  <div class="my-6">
    <WelcomeTutorialBanner title="test" subtitle="test2" />
  </div>
  <h1 class="my-6">
    {{ $t('aiContent.aiContentHeader') }}
  </h1>
  <SubscriptionBlock>AI tvorba obsahu je součástí předplatného <span class="font-semibold">Tanganica Premium.</span> Odemkněte možnosti, které vám pomohou růst.</SubscriptionBlock>
  <ul role="list" class="my-1 grid grid-cols-1 gap-6 rounded-lg sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5">
    <li @click="onSelect(item)" v-for="item in menuItems" :key="item.name" class="relative col-span-1 flex flex-col divide-gray-200 rounded-2xl border border-transparent bg-white text-center shadow-xl hover:cursor-pointer hover:border-blue-600">
      <div class="flex flex-1 flex-col p-4">
        <div class="bg-gray-10 mx-auto h-20 w-20 rounded-none bg-transparent p-1">
          <img class="mx-auto object-contain" :src="$filePath + item.imgSrc" alt="" />
        </div>
        <h3 class="mt-4 text-base font-bold text-main">{{ item.name }}</h3>
        <p class="mt-4 text-sm text-gray-500">{{ item.description }}</p>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { aiContentTypeMenuItems, IMenuItem } from '@/modules/aiContent/codebooks/aiContentTypeMenuItems';
import { ContentChannelEnum } from '@/modules/aiContent/enums/ContentChannelEnum';
import { AI_CONTENT_ROUTES } from '@/modules/aiContent/enums/RoutesEnum';
import SubscriptionBlock from '@/modules/shared/components/SubscriptionBlock.vue';
import WelcomeTutorialBanner from '@/modules/shared/components/WelcomeTutorialBanner.vue';

const props = defineProps({
  channel: {
    type: String as PropType<ContentChannelEnum>,
    required: true,
  },
});

const router = useRouter();

const menuItems = computed<IMenuItem[]>(() => {
  if (!aiContentTypeMenuItems) {
    return [];
  }

  return aiContentTypeMenuItems[props.channel]?.items || [];
});

const onSelect = async (menuItem: IMenuItem) => {
  const params = {
    channel: props.channel,
    type: menuItem.type,
    socialMedia: menuItem?.socialMedia || '',
  };

  await router.push({ name: AI_CONTENT_ROUTES.CREATE, params: params });
};
</script>
