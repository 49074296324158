<template>
  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
  <label :for="props.field.label" class="mb-2 block text-gray-700">{{ props.field.label }}<span v-show="isRequired" class="pl-1 text-red-600">*</span></label>
  <div class="flex flex-wrap justify-start gap-4">
    <div v-for="item in props.field.options" :key="item.label" class="flex items-center">
      <Checkbox :id="item.label" v-model="fieldValue" :value="item.value" />
      <label :for="item.label" class="ml-2"> {{ $t('socialMedia.' + item.label) }} </label>
    </div>
  </div>
  <small class="text-sm text-red-600">{{ errorMessage ? errorMessage : '&nbsp;' }}</small>
</template>
<script setup lang="ts">
import Checkbox from 'primevue/checkbox';
import { useField } from 'vee-validate';
import { computed, PropType } from 'vue';
import { IFormField } from '@/modules/aiContent/interfaces/IFormField';

const props = defineProps({
  field: {
    type: Object as PropType<IFormField<string[]>>,
    required: true,
  },
});

const isRequired = computed(() => {
  if (!props.field?.rules) {
    return false;
  }
  return !props.field.rules.describe().optional;
});

const { value: fieldValue, errorMessage } = useField(props.field?.name, props.field?.rules, {
  initialValue: props.field?.defaultValue || [],
});
</script>
