import { RouteRecordRaw } from 'vue-router';
import { DASHBOARD_ROUTES } from '@/modules/dashboard/enums/RoutesEnum';
import { ModulesEnum } from '@/modules/shared/enums/ModulesEnum';
import { WIZARD_ON_BOARDING_ROUTES } from '@/modules/wizardOnBoarding/enums/RoutesEnum';
import FullPageLayout from '@/oldLayout/FullPageLayout.vue';
import { EnumsMarketingChannel, EnumsMarketingFormat } from '../../../../generated/api';

function isValidCampaign(channel: string): boolean {
  return Object.values(EnumsMarketingChannel).includes(channel as EnumsMarketingChannel);
}

function isValidFormat(format: string): boolean {
  return Object.values(EnumsMarketingFormat).includes(format as EnumsMarketingFormat);
}

const routes: Array<RouteRecordRaw> = [
  {
    path: 'wizardOnBoarding',
    meta: {
      module: ['wizardOnBoarding', 'campaigns', 'credit'],
      layout: FullPageLayout,
      quest: false,
      client: true,
    },
    children: [
      {
        path: '',
        name: WIZARD_ON_BOARDING_ROUTES.INDEX,
        component: () => import('@/modules/wizardOnBoarding/pages/Index.vue'),
        redirect: { name: WIZARD_ON_BOARDING_ROUTES.XML_FEED },
        children: [
          {
            path: 'xmlFeed',
            name: WIZARD_ON_BOARDING_ROUTES.XML_FEED,
            component: () => import('@/modules/wizardOnBoarding/pages/XmlFeed.vue'),
          },
          {
            path: 'pixel',
            name: WIZARD_ON_BOARDING_ROUTES.PIXEL,
            component: () => import('@/modules/wizardOnBoarding/pages/Pixel.vue'),
          },
          {
            path: 'marketingChannel',
            name: WIZARD_ON_BOARDING_ROUTES.MARKETING_CHANNEL,
            component: () => import('@/modules/wizardOnBoarding/pages/MarketingChannel.vue'),
          },
          {
            path: 'marketingFormat/:channel',
            name: WIZARD_ON_BOARDING_ROUTES.MARKETING_FORMAT,
            props: (route) => ({
              channel: route.params.channel as EnumsMarketingChannel,
            }),
            component: () => import('@/modules/wizardOnBoarding/pages/MarketingFormat.vue'),
          },
          {
            path: ':channel/:format/:step',
            name: WIZARD_ON_BOARDING_ROUTES.CAMPAIGN_STEP,
            component: () => import('@/modules/campaigns/pages/Step.vue'),
            props: (route) => ({
              channel: route.params.channel as EnumsMarketingChannel,
              format: route.params.format as EnumsMarketingFormat,
              step: route.params.step,
              module: ModulesEnum.ONBOARDING,
            }),
            beforeEnter: (to, from, next) => {
              if (isValidCampaign(to.params.channel as EnumsMarketingChannel) && isValidFormat(to.params.format as EnumsMarketingFormat)) {
                next();
              } else {
                next({ name: DASHBOARD_ROUTES.INDEX });
              }
            },
          },
          {
            path: 'billing',
            name: WIZARD_ON_BOARDING_ROUTES.BILLING_INFO,
            props: () => ({
              module: ModulesEnum.ONBOARDING,
            }),
            component: () => import('@/modules/credit/pages/BillInformation.vue'),
          },
          {
            path: 'typeOfBilling',
            name: WIZARD_ON_BOARDING_ROUTES.TYPE_OF_BILLING,
            component: () => import('@/modules/wizardOnBoarding/pages/TypeOfBilling.vue'),
          },
          {
            path: 'oneTimeBilling',
            name: WIZARD_ON_BOARDING_ROUTES.ONE_TIME_BILLING,
            props: () => ({
              module: ModulesEnum.ONBOARDING,
            }),
            component: () => import('@/modules/credit/pages/paymentMethod/Gateway.vue'),
          },
          {
            path: 'automaticBilling',
            name: WIZARD_ON_BOARDING_ROUTES.AUTOMATIC_BILLING,
            props: (route) => ({
              budget: route.query.budget,
              module: ModulesEnum.ONBOARDING,
            }),
            component: () => import('@/modules/credit/pages/paymentMethod/Automatic.vue'),
          },
        ],
      },
    ],
  },
];

export default routes;
