<template>
  <label :for="props.field?.name" class="mb-2 block text-lg">{{ props.field.label }}</label>
  <Textarea :id="props.field?.name" :maxlength="maxLength" v-model="inputValue" rows="3" class="w-full" :placeholder="props.field?.placeholder" />
  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
  <span class="ml-auto mr-0 block w-fit font-bold text-main">{{ inputValue ? inputValue.length : 0 }} / {{ maxLength }}</span>
  <small class="text-sm text-red-600">{{ errorMessage ? errorMessage : '&nbsp;' }}</small>
</template>
<script setup lang="ts">
import Textarea from 'primevue/textarea';
import { useField } from 'vee-validate';
import { computed, PropType } from 'vue';
import { IFormField } from '@/modules/aiContent/interfaces/IFormField';

const props = defineProps({
  field: {
    type: Object as PropType<IFormField<string>>,
    required: true,
  },
});

const maxLength = computed(() => {
  return props.field.rules?.describe().tests.find((test: any) => test.name === 'max').params.max;
});

const { value: inputValue, errorMessage } = useField(props.field?.name, props.field?.rules, {
  initialValue: props.field?.defaultValue || '',
});
</script>
