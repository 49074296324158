<template>
  <h1 class="mb-6">{{ $t('aiassistant.Header') }}</h1>
  <SubscriptionBlock>AI asistent je součástí předplatného <span class="font-semibold">Tanganica Premium.</span> Odemkněte možnosti, které vám pomohou růst.</SubscriptionBlock>
  <CardBlock class="relative flex h-full overflow-hidden rounded-lg bg-white">
    <!-- Sidebar -->
    <div :class="[showHistoryMenu ? 'block w-40' : 'hidden w-0', 'absolute h-full overflow-y-auto rounded-md rounded-r-none border-r bg-white']">
      <!-- Sidebar Header -->
      <header class="sticky top-0 z-10 flex w-full items-center justify-between border-b bg-white p-2">
        <FontAwesomeIcon icon="fa-bars" @click="hideThreadMenu()" class="cursor-pointer hover:opacity-80" />
        <FontAwesomeIcon icon="fa-plus" @click="prepareNewThread()" class="cursor-pointer hover:opacity-80" />
      </header>

      <!-- Chat Threads -->
      <div class="h-fit overflow-y-auto p-3">
        <ChatThreads :threads="threads" :selectedAssistant="selectedAssistant" :selectedThread="selectedThread" @onChangeThread="changeThread" @onDeleteThread="deleteThread" />
      </div>
    </div>

    <!-- Main Chat Area -->
    <div :class="[showHistoryMenu ? 'pl-40' : 'pl-0', 'flex w-full flex-1 flex-col']">
      <!-- Chat Messages -->
      <div v-if="isLoadedMessages" ref="chatContainer" class="relative h-full w-full overflow-y-auto px-4">
        <div v-if="!showHistoryMenu" class="sticky left-0 top-0 flex h-10 w-full gap-4 bg-white p-2">
          <FontAwesomeIcon icon="fa-bars" @click="showThreadMenu()" class="cursor-pointer hover:opacity-80" />
          <h5 class="text-sm font-semibold leading-snug text-gray-900">{{ $t('aiassistant.chat') }}</h5>
        </div>
        <div class="mb-4 w-3/4 pt-10">
          <div class="grid">
            <div class="mb-3 flex flex-1 gap-2.5">
              <div class="mr-2 flex h-9 w-9 items-center justify-center rounded-full border">
                <img src="/favicon.png" alt="User Avatar" class="h-full min-w-9 object-contain p-1.5" />
              </div>
              <div class="grid">
                <h5 class="pb-1 text-sm font-semibold leading-snug text-gray-900">{{ $t('aiassistant.aiAssistant') }}</h5>
                <div class="">
                  <div class="inline-flex items-center justify-start gap-3 rounded-lg bg-gray-100 px-3.5 py-2">
                    <h5 class="text-sm font-normal leading-snug text-gray-900">
                      {{ $t('aiassistant.welcomeMessage') }}
                    </h5>
                  </div>
                  <div class="flex justify-end">
                    <h6 class="py-1 text-xs font-normal leading-4 text-gray-500"><LocalizedValue :value="{ value: new Date(), type: 'Date', format: 'long' }"></LocalizedValue></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Assistant -->
        <ChatMessages :messages="messages" />

        <!-- Waiting for answer -->
        <div v-if="isWaitingForAnswer" class="mb-4 flex cursor-pointer">
          <div class="mr-2 flex h-9 w-9 items-center justify-center rounded-full border">
            <img src="/favicon.png" alt="User Avatar" class="W-full h-full object-contain p-1.5" />
          </div>
          <div class="flex max-w-96 gap-3 rounded-lg bg-gray-100 p-3">
            <div class="flex gap-2">
              <div class="h-3 w-3 animate-pulse rounded-full bg-primary-500"></div>
              <div class="h-3 w-3 animate-pulse rounded-full bg-primary-500 delay-500"></div>
              <div class="h-3 w-3 animate-pulse rounded-full bg-primary-500 delay-1000"></div>
            </div>
          </div>
        </div>

        <!-- Ideas for questions -->
        <div v-if="!isWaitingForAnswer && messages?.length === 0" class="mt-10 w-full px-5">
          <ChatIdeas @onAddUserMessage="addUserMessage" />
        </div>
      </div>
      <footer class="mx-auto mb-2 mt-auto h-fit w-[90%] rounded-3xl border p-2">
        <div class="flex w-full max-w-full items-center">
          <textarea
            :disabled="isWaitingForAnswer"
            ref="textarea"
            rows="1"
            type="text"
            :placeholder="$t('aiassistant.typeMessage')"
            class="max-h-32 w-full resize-none overflow-y-auto border-none shadow-none outline-none focus:border-none focus:shadow-none focus:outline-none focus:ring-0 focus:ring-transparent"
            @input="resizeTextArea"
            v-model="userMessage"
            @keydown.enter.prevent="addUserMessage()"
          ></textarea>
          <FontAwesomeIcon @click="addUserMessage()" size="lg" class="ml-2 rounded-full border bg-primary-500 p-2 text-white" icon="fa-regular fa-paper-plane" />
        </div>
      </footer>
    </div>
  </CardBlock>
  <div>
    <!--    <AiAssistantSkeleton />-->
  </div>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { useToast } from 'primevue/usetoast';
import { nextTick, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
// import AiAssistantSkeleton from '@/modules/aiAssistant/components/AiAssistantSkeleton.vue';
import ChatIdeas from '@/modules/aiAssistant/components/ChatIdeas.vue';
import ChatMessages from '@/modules/aiAssistant/components/ChatMessages.vue';
import ChatThreads from '@/modules/aiAssistant/components/ChatThreads.vue';
import CardBlock from '@/modules/shared/components/CardBlock.vue';
import LocalizedValue from '@/modules/shared/components/LocalizedValue.vue';
import SubscriptionBlock from '@/modules/shared/components/SubscriptionBlock.vue';
import { useEshopsStore } from '@/modules/shared/stores/eshops';
import { api } from '@/services/api';
import { handleError } from '@/utils/handleError';
import { EnumsAIMessageRole } from '../../../../generated/api';

const textarea = ref<HTMLTextAreaElement | null>(null);
const chatContainer = ref<HTMLDivElement | null>(null);
const eshopStore = useEshopsStore();
const toast = useToast();
const { t } = useI18n();

const isLoadedMessages = ref(true);
const isWaitingForAnswer = ref(false);

watch(
  () => eshopStore.getSelectedEshop?.id,
  async () => {
    await initChat();
  }
);

onMounted(async () => {
  await initChat();
  resizeTextArea();
});

const initChat = async () => {
  resetChat();
  await getAssistants();
  await getThreads();
  await getMessages();
  await scrollToBottom();
};

const resetChat = () => {
  selectedAssistant.value = null;
  selectedThread.value = null;
  threads.value = [];
  messages.value = [];
};

const resizeTextArea = () => {
  if (textarea.value) {
    textarea.value.style.height = 'auto'; // Reset height
    textarea.value.style.height = `${textarea.value.scrollHeight}px`; // New height based on content
  }
};

const scrollToBottom = async () => {
  if (chatContainer.value) {
    await nextTick();
    chatContainer.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }
};

// AI Assistant
const selectedAssistant = ref();

const getAssistants = async () => {
  try {
    const { data } = await api.clientAssistantGetAssistants();
    // Now get first assistant // TODO check what to do if there are more assistants
    selectedAssistant.value = data.assistants[0]?.assistantId || null;
  } catch (error: any) {
    handleError(error);
  }
};

// Threads
const showHistoryMenu = ref(true);

const hideThreadMenu = () => {
  showHistoryMenu.value = false;
};

const showThreadMenu = () => {
  showHistoryMenu.value = true;
};

const threads = ref();
const selectedThread = ref();

const createNewThreadObject = () => ({
  threadId: crypto.randomUUID(),
  name: t('aiassistant.newThread'),
  isNew: true,
});

// TODO auto scroll fix

const getThreads = async () => {
  try {
    const { data } = await api.clientAssistantGetThreads({ assistantId: selectedAssistant.value });
    threads.value = data.threads || [];
    if (threads.value.length > 0) {
      selectedThread.value = threads.value[0];
    }
  } catch (error: any) {
    handleError(error);
  }
};

const createThread = async (message: string) => {
  // If there is already a selected thread and the thread is not new, do not create a new thread
  if (selectedThread.value && !selectedThread.value.isNew) {
    return true;
  }

  const threadName = message.substring(0, 10) + '...';

  try {
    const { data } = await api.clientAssistantCreateThread({ assistantId: selectedAssistant.value, name: threadName });

    if (!data.threadId) {
      return false;
    }

    // Remove the new thread placeholder
    threads.value = threads.value.filter((thread) => !thread.isNew);

    // Add new thread to the list
    threads.value.unshift({ threadId: data.threadId, name: threadName });

    // Select the new thread
    selectedThread.value = threads.value[0];
    return true;
  } catch (error: any) {
    handleError(error);
    return false;
  }
};

const changeThread = async (thread) => {
  isLoadedMessages.value = false;
  selectedThread.value = thread;

  // TODO WHY IS THIS HERE? maybe for removing new thread when switching to another thread / check when BE ready
  // threads.value = threads.value.filter((thread) => thread.threadId !== 'new thread');

  if (thread.isNew) {
    messages.value = [];
    isLoadedMessages.value = true;
    return;
  }

  await getMessages();
  isLoadedMessages.value = true;
  await scrollToBottom();
};

const prepareNewThread = () => {
  // If there is already a new thread, do not create a new one
  if (selectedThread.value?.isNew) {
    return;
  }

  const newThreadObject = createNewThreadObject();

  threads.value.unshift(newThreadObject);

  selectedThread.value = newThreadObject;

  messages.value = [];
};

const deleteThread = async (threadId: string) => {
  try {
    await api.clientAssistantDeleteThread({ threadId, assistantId: selectedAssistant.value });

    threads.value = threads.value.filter((thread) => thread.threadId !== threadId);

    if (selectedThread.value === threadId) {
      selectedThread.value = threads.value[0] || null;

      if (selectedThread.value) {
        await getMessages();
      } else {
        messages.value = [];
      }
    }

    toast.add({
      severity: 'success',
      summary: t('aiassistant.threadDeleted'),
      life: 6000,
    });
  } catch (error: any) {
    handleError(error);
  }
};

// Messages
const messages = ref();

const getMessages = async () => {
  // If no thread is selected, do not fetch messages
  if (!selectedThread.value) {
    messages.value = [];
    return;
  }
  try {
    const { data } = await api.clientAssistantGetMessages({ threadId: selectedThread.value });

    if (!data.messages || data.messages.length === 0) {
      messages.value = [];
      return;
    }

    // Sort messages by createdAt
    messages.value = data.messages.sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return 0;
      return DateTime.fromISO(a.createdAt).toMillis() - DateTime.fromISO(b.createdAt).toMillis();
    });
  } catch (error: any) {
    handleError(error);
  }
};

const userMessage = ref('');

const createNewUserMessageObject = (content) => ({
  messageId: crypto.randomUUID(),
  content,
  role: EnumsAIMessageRole.User,
  createdAt: DateTime.now().toISO(),
  isNew: true,
});

const addUserMessage = async (defaultQuestionMessage: string | undefined = undefined) => {
  const newUserMessage = userMessage.value || defaultQuestionMessage; // defaultQuestionMessage is used when user clicks on a question

  if (!newUserMessage) {
    console.warn('No message to send');
    return;
  }

  const isThreadCreated = await createThread(newUserMessage);

  if (!isThreadCreated) {
    console.error('Thread could not be created');
    return;
  }

  try {
    isWaitingForAnswer.value = true;

    const newMessageObject = createNewUserMessageObject(newUserMessage);
    messages.value.push(newMessageObject);

    await scrollToBottom();

    const isMessageSent = await sendUserMessage(newUserMessage);

    if (!isMessageSent) {
      console.error('Message could not be sent');
      return;
    }

    resizeTextArea();

    await scrollToBottom();
  } catch (error) {
    handleError(error);
  } finally {
    isWaitingForAnswer.value = false;
  }
};

const sendUserMessage = async (message) => {
  const lastMessageId = messages.value[messages.value.length - 2]?.messageId || null;

  try {
    const { data } = await api.clientAssistantAddUserMessage({
      assistantId: selectedAssistant.value,
      threadId: selectedThread.value,
      message,
      lastMessageId,
    });

    if (!data.messages || data.messages.length === 0) {
      return false;
    }

    // Remove the new message placeholder
    messages.value = messages.value.filter((msg) => !msg.isNew);

    // Add new messages to the list
    messages.value.push(...data.messages);

    return true;
  } catch (error: any) {
    handleError(error);
    return false;
  }
};
</script>
<style>
b {
  font-weight: 900;
  color: black;
  font-size: 0.9rem;
}
</style>
