import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/Aura';
import { PrimeVueConfiguration } from 'primevue';
import PrimeVue, { defaultOptions } from 'primevue/config';
import { App, toRaw } from 'vue';
import i18n from '@/plugins/i18n';

const Tanganica = definePreset(Aura, {
  semantic: {
    primary: {
      50: '#0756950d',
      100: '#0756950d',
      200: '#80b5d8',
      300: '#4d98c9',
      400: '#267fb8',
      500: '#0065a6',
      600: '#005792',
      700: '#004478',
      800: '#00386c',
      900: '#00386c',
      950: '#00386c',
    },
    highlight: {
      50: '#fef4e6',
      100: '#fce6b2',
      200: '#fbd78c',
      300: '#f8c759',
      400: '#f6b639',
      500: '#f6941a',
      600: '#f67e1b',
      700: '#f6691c',
      800: '#f6531d',
      900: '#f67424',
    },
    formField: {
      focus: {
        ring: {
          width: '1px',
          style: 'solid',
          color: '{primary.900}',
          offset: '0px',
        },
      },
    },
  },
  components: {
    button: {
      lg: {
        padding: {
          x: '10rem',
        },
      },
      colorScheme: {
        root: {
          primary: {
            color: 'white',
            background: '{primary.950}',
            hover: {
              background: '{primary.700}',
            },
          },
          contrast: {
            color: 'white',
            border: {
              color: '#f67424',
              width: '1px',
              style: 'solid',
            },
            background: 'linear-gradient(to right, #f67424, #ea4335)',
            hover: {
              background: 'linear-gradient(to right, #ea4335, #f67424)',
              border: {
                color: '#f67424',
                width: '1px',
                style: 'solid',
              },
            },
          },
        },
      },
    },
    select: {
      border: {
        color: '{primary.950}',
        width: '1px',
      },
      padding: {
        x: '1rem',
        y: '0.4rem',
      },
    },
    inputtext: {
      border: {
        color: '{primary.950}',
        width: '1px',
      },
    },
    progressbar: {
      height: '1rem',
      value: {
        background: '{primary.900}',
      },
      // border: {
      //   radius: '0rem',
      // }
    },
    tabs: {
      bar: {
        background: '#ffffff',
      },

      border: {
        color: '#ffffff',
        width: '1px',
        style: 'solid',
      },

      tablist: {
        background: '#ffffff',
        color: '#ffffff',
        border: {
          radius: '0.5rem',
          color: '#ffffff',
        },
      },
      tab: {
        padding: '0.5rem 1rem',
        margin: '0 0.5rem',
        color: '{gray.500}',
        border: {
          color: '',
          style: 'solid',
        },
        hover: {
          color: '{primary-950}',
          border: {
            color: '{primary-950}',
            width: '1px',
            style: 'solid',
          },
        },
        active: {
          color: '{primary-950}',
          border: {
            color: '{primary-950}',
            width: '1px',
            style: 'solid',
          },
        },
      },
    },
    fileupload: {
      header: {
        padding: '1.125rem 0',
      },
      content: {
        padding: '1.125rem 0',
      },
    },
    toggleswitch: {
      checked: {
        background: '#2fb210 !important', // Idon't know why the !important is needed primevue rocks
        hover: {
            background: '#4ad022 !important',
        }
      },
    },
  },
});

export function installPrimeVue(app: App) {
  // Use PrimeVue with preset and locale settings
  app.use(PrimeVue, {
    theme: {
      preset: Tanganica,
      options: {
        darkModeSelector: '.dark-mode',
        cssLayer: {
          name: 'primevue',
          order: 'tailwind-base, primevue, tailwind-utilities',
        },
      },
    },
    locale: {
      ...defaultOptions.locale,
    },
  });
}

export function setPrimeVueLanguage(primeVueInstance: { config: PrimeVueConfiguration }, languageCode: string) {
  const rootKey = 'shared';
  const baseKey = 'primeVue';
  const translationFn = i18n.global.t as (key: string) => string;

  const primeVueMessages = toRaw(i18n.global.getLocaleMessage(languageCode)[rootKey][baseKey]);

  if (!primeVueMessages || !primeVueInstance || !primeVueInstance.config || !primeVueInstance.config.locale) {
    return;
  }

  function setNestedProperty(obj: Record<string, any>, path: string, value: any) {
    const keys = path.split('.');

    let current = obj;
    while (keys.length > 1) {
      const key = keys.shift()!;
      if (!current[key]) {
        current[key] = {};
      }
      current = current[key];
    }

    if (keys[0]) {
      current[keys[0]] = value;
    }
  }

  function updateLocale(messages: any, key?: string) {
    Object.keys(messages).forEach((messageKey) => {
      const value = messages[messageKey];
      const translationKey = `${rootKey}.${baseKey}` + (key ? `.${key}` : '') + `.${messageKey}`;
      const primeVueConfigKey = key ? `${key}.${messageKey}` : messageKey;

      if (!primeVueInstance.config.locale) return;

      if (typeof value === 'function') {
        setNestedProperty(primeVueInstance.config.locale, primeVueConfigKey, translationFn(translationKey));
      } else if (Array.isArray(value)) {
        const arrayOfMessages: string[] = [];
        for (let i = 0; i < value.length; i++) {
          arrayOfMessages.push(translationFn(`${translationKey}.${i}`));
        }
        setNestedProperty(primeVueInstance.config.locale, primeVueConfigKey, arrayOfMessages);
      } else if (typeof value === 'object' && value !== null) {
        updateLocale(value, primeVueConfigKey);
      } else {
        setNestedProperty(primeVueInstance.config.locale, primeVueConfigKey, value);
      }
    });
  }

  updateLocale(primeVueMessages);
}
