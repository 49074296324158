import messages from '@intlify/unplugin-vue-i18n/messages';
import { isRef, nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import type { I18n, I18nOptions, Locale, VueI18n, Composer, I18nMode } from 'vue-i18n';

// Define your custom datetime formats type
type ICustomDateTimeFormats = {
  [locale: string]: {
    short?: Intl.DateTimeFormatOptions;
    long?: Intl.DateTimeFormatOptions;
    format?: string;
  };
};

// Define your custom number formats type
type ICustomNumberFormats = {
  [locale: string]: {
    currency?: Intl.NumberFormatOptions;
    percent?: Intl.NumberFormatOptions;
    decimal?: Intl.NumberFormatOptions;
  };
};

export const datetimeFormats: ICustomDateTimeFormats = {
  cs: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd.MM.yyyy HH:mm',
  },
  sk: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd.MM.yyyy HH:mm',
  },
  en: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'MM/dd/yyyy HH:mm',
  },
  da: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd-MM-yyyy HH:mm',
  },
  de: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd.MM.yyyy HH:mm',
  },
  el: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd/MM/yyyy HH:mm',
  },
  es: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd/MM/yyyy HH:mm',
  },
  fi: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd.MM.yyyy HH:mm',
  },
  fr: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd/MM/yyyy HH:mm',
  },
  hu: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'yyyy.MM.dd. HH:mm',
  },
  it: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd/MM/yyyy HH:mm',
  },
  nl: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd-MM-yyyy HH:mm',
  },
  nn: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd.MM.yyyy HH:mm',
  },
  pl: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd.MM.yyyy HH:mm',
  },
  pt: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd/MM/yyyy HH:mm',
  },
  ro: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'dd.MM.yyyy HH:mm',
  },
  sv: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    format: 'yyyy-MM-dd HH:mm',
  },
};

export const numberFormats: ICustomNumberFormats = {
  cs: {
    currency: {
      style: 'currency',
      currency: 'CZK',
      useGrouping: true,
      notation: 'standard',
      currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', // Format as a percentage
      minimumFractionDigits: 2, // Minimum number of decimal places
      maximumFractionDigits: 2, // Maximum number of decimal places
      useGrouping: false,
    },
  },
  sk: {
    currency: {
      style: 'currency',
      currency: 'CZK',
      useGrouping: true,
      notation: 'standard',
      currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent', // Format as a percentage
      minimumFractionDigits: 2, // Minimum number of decimal places
      maximumFractionDigits: 2, // Maximum number of decimal places
      useGrouping: false,
    },
  },
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      notation: 'standard',
      currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal',
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 2,
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 2, // Minimum number of decimal places
      maximumFractionDigits: 2, // Maximum number of decimal places
      useGrouping: false,
    },
  },
};

const i18nConfig = {
  legacy: false,
  useScope: 'global',
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
  datetimeFormats: datetimeFormats,
  numberFormats: numberFormats,
  missingWarn: false,
};

function isComposer(instance: VueI18n | Composer, mode: I18nMode): instance is Composer {
  return mode === 'composition' && isRef(instance.locale);
}

export function setLocale(i18n: I18n, locale: Locale): void {
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale;
  } else {
    i18n.global.locale = locale;
  }
}

const i18n = setupI18n(i18nConfig);

export function setupI18n(options: I18nOptions): I18n {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale!);
  return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: Locale): void {
  setLocale(i18n, locale);
  // api.defaults.headers.common['Accept-Language'] = locale;
  document.querySelector('html')!.setAttribute('lang', locale);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getResourceMessages = (r: any) => r.default || r;

type loadedModules = {
  locale: Locale;
  module: string;
};

const loadedModules: loadedModules[] = [];

export async function loadLocaleMessages(i18n: I18n, locale: Locale, module?: string) {
  if (module) {
    // Check if module is not already loaded
    const existingModule = loadedModules.find((loadedModule) => loadedModule.locale === locale && loadedModule.module === module);

    if (!existingModule) {
      try {
        // Load module messages
        const moduleMessages = await import(`../modules/${module}/translations/${locale}.json`).then(getResourceMessages);

        // Merge current messages with loaded messages
        i18n.global.mergeLocaleMessage(locale, moduleMessages);

        // Add module to loaded modules
        loadedModules.push({ locale, module });
      } catch (error) {
        console.warn(`Module translation file not found for locale ${locale} and module ${module}`);
      }
    }
  }
  return nextTick();
}

export default i18n;
