<template>
  <Dialog v-model:visible="premiumModal.isOpen.value" modal class="max-w-lg" :draggable="false" header="&nbsp;">
    <h2 class="text-pretty text-center text-lg font-bold tracking-tight text-primary-900 md:text-xl xl:text-2xl">Odemkněte možnosti, které vám pomohou růst</h2>
    <div class="mt-8 px-0 text-center md:px-4">
      <p class="text-gray-600">
        Tato funkce je součástí <span class="font-semibold text-highlight-900">Tanganica Premium</span> – balíčku, který nabízí nástroje pro lepší výkon reklam a podrobné analýzy. Zvyšte své prodeje a získávejte výsledky, o kterých jste snili.
      </p>
      <div class="my-8">
        <div class="mx-auto w-fit">
          <Button severity="contrast" @click="getPremium" size="small">
            {{ $t('Vyzkoušet na 14 dní zdarma') }}
            <FontAwesomeIcon icon="fa-angle-right" />
          </Button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useRouter } from 'vue-router';
import { CREDIT_ROUTES } from '@/modules/credit/enums/RoutesEnum';
import { usePremiumModal } from '@/modules/shared/composables/usePremiumModal';

const premiumModal = usePremiumModal();
const router = useRouter();

const getPremium = async () => {
  try {
    await router.push({ name: CREDIT_ROUTES.SUBSCRIPTION });
  } catch (e) {
    console.warn('Error while redirecting to subscription page', e);
  }
};
</script>
