export enum WIZARD_ON_BOARDING_ROUTES {
    INDEX = 'routesWizardOnboardingIndex',
    XML_FEED = 'routesWizardOnboardingXmlFeed',
    PIXEL = 'routesWizardOnboardingPixel',
    MARKETING_CHANNEL = 'routesWizardOnboardingMarketingChannel',
    MARKETING_FORMAT = 'routesWizardOnboardingMarketingFormat',
    CAMPAIGN_STEP = 'routesWizardOnboardingCampaignStep',
    BILLING_INFO = 'routesWizardOnboardingBillingInfo',
    TYPE_OF_BILLING = 'routesWizardOnboardingTypeOfBilling',
    ONE_TIME_BILLING = 'routesWizardOnboardingOneTimeBilling',
    AUTOMATIC_BILLING = 'routesWizardOnboardingAutomaticBilling',
}
