import { RouteRecordRaw } from 'vue-router';
import ClientLayout from '@/layout/ClientLayout.vue';
import { ContentChannelEnum } from '@/modules/aiContent/enums/ContentChannelEnum';
import { AI_CONTENT_ROUTES } from '@/modules/aicontent/enums/RoutesEnum';
import ContentCreatePage from '@/modules/aicontent/pages/ContentCreate.vue';
import ContentSelection from '@/modules/aiContent/pages/ContentSelection.vue';
import AiContentPage from '@/modules/aicontent/pages/Index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'aicontent',
    name: AI_CONTENT_ROUTES.INDEX,
    component: AiContentPage,
    redirect: { name: AI_CONTENT_ROUTES.SELECTION, params: { channel: ContentChannelEnum.SocialMedia } },
    meta: {
      module: 'aiContent',
      layout: ClientLayout,
      quest: false,
      client: true,
    },
    children: [
      {
        path: ':channel',
        name: AI_CONTENT_ROUTES.SELECTION,
        props: (route) => ({ channel: route.params.channel }),
        component: ContentSelection,
      },
      {
        path: 'create/:channel/:type/:socialMedia?',
        name: AI_CONTENT_ROUTES.CREATE,
        props: (route) => ({ type: route.params.type, socialMedia: route.params.socialMedia || '' }),
        component: ContentCreatePage,
      },
    ],
  },
];

export default routes;
