import { RouteRecordRaw } from 'vue-router';
import ClientLayout from '@/layout/ClientLayout.vue';
import { CREDIT_ROUTES } from '@/modules/credit/enums/RoutesEnum';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'credit',
    meta: {
      module: 'credit',
      layout: ClientLayout,
      quest: false,
      client: true,
    },
    name: CREDIT_ROUTES.INDEX,
    redirect: { name: CREDIT_ROUTES.AUTOMATIC },
    component: () => import('@/modules/credit/pages/Index.vue'),
    children: [
      {
        path: 'recharge',
        name: CREDIT_ROUTES.RECHARGE,
        redirect: { name: CREDIT_ROUTES.GATEWAY },
        children: [
          {
            path: 'gateway',
            name: CREDIT_ROUTES.GATEWAY,
            component: () => import('@/modules/credit/pages/paymentMethod/Gateway.vue'),
          },
          {
            path: 'bank',
            name: CREDIT_ROUTES.BANK,
            component: () => import('@/modules/credit/pages/paymentMethod/BankTransfer.vue'),
          },
        ],
      },
      {
        path: 'automatic',
        name: CREDIT_ROUTES.AUTOMATIC,
        component: () => import('@/modules/credit/pages/paymentMethod/Automatic.vue'),
      },
      {
        path: 'subscription',
        name: CREDIT_ROUTES.SUBSCRIPTION,
        component: () => import('@/modules/credit/pages/Subscription.vue'),
      },
      {
        path: 'bill-info',
        name: CREDIT_ROUTES.BILL_INFO,
        component: () => import('@/modules/credit/pages/BillInformation.vue'),
      },
      {
        path: 'payment-history',
        name: CREDIT_ROUTES.PAYMENT_HISTORY,
        component: () => import('@/modules/credit/pages/PaymentHistory.vue'),
      },
    ],
  },
];

export default routes;
