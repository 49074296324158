<template>
  <div class="my-8 w-full text-center">
    <p class="text-highlight-900">
      <slot></slot>
    </p>
    <Button severity="contrast" @click="goToSubscription" size="small" class="mt-4">
      {{ $t('Vyzkoušet na 14 dní zdarma') }}
      <FontAwesomeIcon icon="fa-angle-right" />
    </Button>
  </div>
</template>
<script setup lang="ts">
import Button from 'primevue/button';
import { useRouter } from 'vue-router';
import { CREDIT_ROUTES } from '@/modules/credit/enums/RoutesEnum';

const router = useRouter();

const goToSubscription = async () => {
  try {
    await router.push({ name: CREDIT_ROUTES.SUBSCRIPTION });
  } catch (e) {
    console.warn('Error while redirecting to subscription page', e);
  }
};
</script>
