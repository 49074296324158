import { ContentChannelEnum } from '@/modules/aiContent/enums/ContentChannelEnum';
import { ContentTypeEnum } from '@/modules/aiContent/enums/ContentTypeEnum';
import i18n from '@/plugins/i18n';
import { EnumsSocialMedia } from '../../../../generated/api';

export interface IMenuItem {
  name: string;
  description: string;
  type: string;
  socialMedia?: string;
  imgSrc: string;
}

const { t } = i18n.global;

export const aiContentTypeMenuItems: {
  [key: string]: {
    items: IMenuItem[];
  };
} = {
  [ContentChannelEnum.SocialMedia]: {
    items: [
      {
        name: t('socialMedia.GenerateSocialMediaContentIdeaFacebookHeader'),
        description: t('socialMedia.GenerateSocialMediaContentIdeaFacebookDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContentIdea,
        socialMedia: EnumsSocialMedia.Facebook,
        imgSrc: '/socialContent/ContentIdeaFacebook.png',
      },
      {
        name: t('socialMedia.GenerateSocialMediaContentIdeaInstagramHeader'),
        description: t('socialMedia.GenerateSocialMediaContentIdeaInstagramDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContentIdea,
        socialMedia: EnumsSocialMedia.Instagram,
        imgSrc: '/socialContent/ContentIdeaInstagram.png',
      },
      {
        name: t('socialMedia.GenerateSocialMediaContentIdeaLinkedinHeader'),
        description: t('socialMedia.GenerateSocialMediaContentIdeaLinkedinDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContentIdea,
        socialMedia: EnumsSocialMedia.LinkedIn,
        imgSrc: '/socialContent/ContentIdeaLinkedin.png',
      },
      {
        name: t('socialMedia.GenerateSocialMediaContentFacebookHeader'),
        description: t('socialMedia.GenerateSocialMediaContentFacebookDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContent,
        socialMedia: EnumsSocialMedia.Facebook,
        imgSrc: '/socialContent/ContentFacebook.png',
      },
      {
        name: t('socialMedia.GenerateSocialMediaContentInstagramHeader'),
        description: t('socialMedia.GenerateSocialMediaContentInstagramDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContent,
        socialMedia: EnumsSocialMedia.Instagram,
        imgSrc: '/socialContent/ContentInstagram.png',
      },
      {
        name: t('socialMedia.GenerateSocialMediaContentLinkedinHeader'),
        description: t('socialMedia.GenerateSocialMediaContentLinkedinDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContent,
        socialMedia: EnumsSocialMedia.LinkedIn,
        imgSrc: '/socialContent/ContentLinkedin.png',
      },
      {
        name: t('socialMedia.YoutubeIdeaHeader'),
        description: t('socialMedia.YoutubeIdeaDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContentIdea,
        socialMedia: EnumsSocialMedia.YouTube,
        imgSrc: '/socialContent/YoutubeIdea.png',
      },
      {
        name: t('socialMedia.YoutubeIdeaNameHeader'),
        description: t('socialMedia.YoutubeIdeaNameDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContentHeadline,
        socialMedia: EnumsSocialMedia.YouTube,
        imgSrc: '/socialContent/YoutubeIdeaName.png',
      },
      {
        name: t('socialMedia.ContentStrategyFacebookHeader'),
        description: t('socialMedia.ContentStrategyFacebookDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContentStrategy,
        socialMedia: EnumsSocialMedia.Facebook,
        imgSrc: '/socialContent/ContentStrategyFacebook.png',
      },
      {
        name: t('socialMedia.ContentStrategyInstagramHeader'),
        description: t('socialMedia.ContentStrategyInstagramDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContentStrategy,
        socialMedia: EnumsSocialMedia.Instagram,
        imgSrc: '/socialContent/ContentStrategyInsta.png',
      },
      {
        name: t('socialMedia.ContentStrategyLinkedinHeader'),
        description: t('socialMedia.ContentStrategyLinkedinDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContentStrategy,
        socialMedia: EnumsSocialMedia.LinkedIn,
        imgSrc: '/socialContent/ContentStrategyLinkedin.png',
      },
      {
        name: t('socialMedia.ContentStrategyYoutubeHeader'),
        description: t('socialMedia.ContentStrategyYoutubeDescription'),
        type: ContentTypeEnum.GenerateSocialMediaContentStrategy,
        socialMedia: EnumsSocialMedia.YouTube,
        imgSrc: '/socialContent/ContentStrategyYoutube.png',
      },
    ],
  },
  [ContentChannelEnum.Blog]: {
    items: [
      {
        name: t('socialMedia.GenerateBlogGivenTopicHeader'),
        description: t('socialMedia.GenerateBlogGivenTopicDescription'),
        type: ContentTypeEnum.GenerateBlogContent,
        imgSrc: '/socialContent/BlogGivenTopic.png',
      },
      {
        name: t('socialMedia.GenerateBlogStrategyHeader'),
        description: t('socialMedia.GenerateBlogStrategyDescription'),
        type: ContentTypeEnum.GenerateBlogContentStrategy,
        imgSrc: '/socialContent/BlogStrategy.png',
      },
      {
        name: t('socialMedia.GenerateIdeasForBlogArticleHeader'),
        description: t('socialMedia.GenerateIdeasForBlogArticleDescription'),
        type: ContentTypeEnum.GenerateBlogContentIdea,
        imgSrc: '/socialContent/BlogIdeas.png',
      },
      {
        name: t('socialMedia.GenerateSeoForBlogHeader'),
        description: t('socialMedia.GenerateSeoForBlogDescription'),
        type: ContentTypeEnum.OptimizeBlogContent,
        imgSrc: '/socialContent/BlogSeo.png',
      },
    ],
  },
  [ContentChannelEnum.Email]: {
    items: [
      {
        name: t('socialMedia.GenerateEmailIdeasForNewsletterHeader'),
        description: t('socialMedia.GenerateEmailIdeasForNewsletterDescription'),
        type: ContentTypeEnum.GenerateNewsletterIdea,
        imgSrc: '/socialContent/EmailIdeasForNewsletter.png',
      },
      {
        name: t('socialMedia.GenerateEmailIdeasForNewsletterTextHeader'),
        description: t('socialMedia.GenerateEmailIdeasForNewsletterTextDescription'),
        type: ContentTypeEnum.GenerateNewsletter,
        imgSrc: '/socialContent/EmailIdeasForNewsletterText.png',
      },
      {
        name: t('socialMedia.GenerateEmailContentStrategyHeader'),
        description: t('socialMedia.GenerateEmailContentStrategyDescription'),
        type: ContentTypeEnum.GenerateEmailContentStrategy,
        imgSrc: '/socialContent/EmailContentStrategy.png',
      },
    ],
  },
};
