<template>
  <!-- Template 2 -->
  <CardBlock class="block lg:hidden">
    <div class="">
      <div class="">
        <div class="">
          <div class="max-w-2xl lg:mx-0 lg:max-w-lg">
            <h2 class="text-xs font-semibold text-primary-600">{{ subtitle }}</h2>
            <h2 class="text-pretty text-xl font-semibold tracking-tight text-primary-900 md:text-3xl xl:text-4xl">{{ title }}</h2>
            <p class="mt-6 text-pretty text-sm text-primary-500">Postupujte podle bodů níže a spusťe reklamy na celém internetu.</p>
          </div>
        </div>
        <div class="mx-auto">
          <div class="relative isolate hidden overflow-hidden bg-primary-900 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pr-0 lg:mx-0 lg:block lg:max-w-none">
            <div class="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white" aria-hidden="true" />
            <div class="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
              <img src="https://cdn.prod.website-files.com/66d613d0cdd5c6fce8bb3005/671b62c527fa65dd475ba839_new_product.png" class="-mb-12 max-h-64 rounded-tl-xl" />
            </div>
            <div class="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl" aria-hidden="true" />
          </div>
        </div>
      </div>
      <dl class="mt-10 grid w-full grid-cols-1 gap-x-10 gap-y-8 text-base/7 text-gray-700 lg:grid-cols-2">
        <Fieldset v-for="feature in features" :key="feature.name" :collapsed="!feature.active" :legend="feature.name" :toggleable="true" class="relative h-fit rounded-lg border border-gray-100 bg-white p-2 shadow-lg">
          <dt class="inline font-semibold text-primary-900">
            <FontAwesomeIcon :icon="feature.icon"></FontAwesomeIcon>
            {{ feature.name }}
          </dt>
          {{ ' ' }}
          <dd class="inline text-primary-500">{{ feature.description }}</dd>
          {{ ' ' }}
          <button class="ml-auto mt-1 block rounded-full bg-highlight-900 p-1 px-2 text-sm text-white hover:bg-highlight-800" aria-label="Zobrazit více informací">Spustit průvodce</button>
        </Fieldset>
      </dl>
    </div>
  </CardBlock>

  <!-- Template 4-->
  <CardBlock class="drop-shadow- glowing hidden lg:block">
    <h2 class="text-base/7 font-semibold text-primary-600">{{ subtitle }}</h2>
    <h2 class="text-pretty text-3xl font-semibold tracking-tight text-primary-900">{{ title }}</h2>
    <div class="mt-5 flex divide-x">
      <div class="mr-5 min-w-fit">
        <ul class="flex flex-col gap-y-4 pl-8">
          <li v-for="(feature, index) in features" :key="feature.name" class="flex gap-y-4" @mouseover="onToggleFeature(features[index], index)" @click="onToggleFeature(features[index], index)">
            <div :class="[feature.selected ? 'bg-primary-50' : 'bg-white', 'flex cursor-pointer items-center rounded-md p-1 hover:bg-primary-50']">
              <FontAwesomeIcon icon="fa fa-circle-dot" class="mr-2 rounded-full bg-gray-400 text-gray-400"></FontAwesomeIcon>
              <span class="text-primary-900 lg:text-base">{{ feature.name }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="w-full">
        <div class="ml-5 flex max-w-sm flex-col gap-4">
          <h3 class="text-lg text-primary-900">{{ toggleFeature.name }}</h3>
          <p class="text-gray-700">{{ toggleFeature.description }}</p>
          <Button @click="routeTo(toggleFeature.route)" class="mx-auto h-8 w-2/3 text-base shadow shadow-gray-400" type="submit" severity="contrast" :label="'Spustit'" />
        </div>
      </div>
    </div>
  </CardBlock>
</template>
<script setup lang="ts">
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import CardBlock from '@/modules/shared/components/CardBlock.vue';
import { WIZARD_ON_BOARDING_ROUTES } from '@/modules/wizardOnboarding/enums/RoutesEnum';

const props = defineProps({
  subtitle: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
});
const router = useRouter();
const features = ref([
  {
    name: 'Nahrejte váš produktový feed.',
    description: 'Abychom mohli propagovat vaše produkty, musíme mít k dispozici informace o nich. Nahrajte svůj produktový feed a my se postaráme o zbytek.',
    icon: 'fa fa-circle-dot',
    active: true,
    selected: true,
    route: WIZARD_ON_BOARDING_ROUTES.XML_FEED,
  },
  {
    name: 'Nastavte měřící kód.',
    description: 'Abychom mohli měřit výkon vašich reklam, je potřeba nastavit měřící kód. Bez něj nebudete moci sledovat výkon vašich reklam.',
    icon: 'fa fa-circle-dot',
    active: false,
    selected: false,
    route: WIZARD_ON_BOARDING_ROUTES.PIXEL,
  },
  {
    name: 'Nahrajte podklady pro kampaně.',
    description: 'Nahrajte obrázky, texty a další podklady, které chcete použít ve vašich reklamních kampaních. Bez nich nebudete moci spustit reklamy.',
    icon: 'fa fa-circle-dot',
    active: false,
    selected: false,
    route: WIZARD_ON_BOARDING_ROUTES.XML_FEED,
  },
  {
    name: 'Dobijte reklamní kredit.',
    description: 'Reklamní kredit je virtuální měna, kterou používáme pro spouštění reklamních kampaní. Dobijte si kredit a můžete začít propagovat vaše produkty.',
    icon: 'fa fa-circle-dot',
    active: false,
    selected: false,
    route: WIZARD_ON_BOARDING_ROUTES.XML_FEED,
  },
  {
    name: 'Kupte si přeplatné',
    description: 'Přeplatné vám umožní získat výhody, které nemají ostatní uživatelé. Kupte si přeplatné a buďte o krok napřed.',
    icon: 'fa fa-circle-dot',
    active: false,
    selected: false,
    route: WIZARD_ON_BOARDING_ROUTES.XML_FEED,
  },
]);
const toggleFeature = ref(features.value[0]);
const onToggleFeature = (feature: any, index) => {
  toggleFeature.value = feature;
  features.value.forEach((f, i) => {
    f.selected = i === index;
  });
};

const routeTo = (pathName) => {
  router.push({ name: pathName });
};
</script>
<style lang="css" scoped>
.glowing {
  box-shadow: 0 0 6px 2px #004483;
}
</style>
