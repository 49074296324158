import { RouteRecordRaw } from 'vue-router';
import ClientLayout from '@/layout/ClientLayout.vue';
import { AI_ASSISTANT_ROUTES } from '@/modules/aiAssistant/enums/RoutesEnum';
import AiAssistantPage from '@/modules/aiAssistant/pages/Index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'aiassistant',
    meta: {
      module: 'aiAssistant',
      layout: ClientLayout,
      quest: false,
      client: true,
    },
    name: AI_ASSISTANT_ROUTES.INDEX,
    component: AiAssistantPage,
    //  meta: {
    //    permissions: {
    //      list: [EnumsClientPermission.AiContentGeneration],
    //      condition: 'or',
    //    },
    // },
  },
];

export default routes;
