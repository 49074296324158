<template>
  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
  <label :for="props.field.name" class="mb-2 block text-lg text-main">{{ props.field.label }}<span v-show="isRequired" class="pl-1 text-red-600">*</span></label>
  <div class="flex flex-wrap justify-start gap-4 placeholder:text-gray-500">
    <Select :id="props.field.name" v-model="inputValue" :options="props.field.options" optionLabel="label">
      <template #value="slotProps">
        <div v-if="slotProps.value" class="flex items-center">
          <span>{{ slotProps.value.label }}</span>
        </div>
        <div v-else class="flex items-center text-gray-500">
          <span>{{ props.field.placeholder }}</span>
        </div>
      </template>
      <template #option="slotProps">
        <div class="flex items-center">
          <span>{{ slotProps.option.label }}</span>
        </div>
      </template>
    </Select>
  </div>
  <small class="text-sm text-red-600">{{ errorMessage ? errorMessage : '&nbsp;' }}</small>
</template>
<script setup lang="ts">
import Select from 'primevue/select';
import { useField } from 'vee-validate';
import { computed, PropType } from 'vue';
import { IFormField } from '@/modules/aiContent/interfaces/IFormField';

const props = defineProps({
  field: {
    type: Object as PropType<IFormField<string>>,
    required: true,
  },
});

const isRequired = computed(() => {
  if (!props.field?.rules) {
    return false;
  }
  return !props.field.rules.describe().optional;
});

const { value: inputValue, errorMessage } = useField(props.field?.name, props.field?.rules, {
  initialValue: props.field?.defaultValue || '',
});
</script>
