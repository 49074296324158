<template>
  <AutoComplete v-model="inputValue" multiple fluid :typeahead="false" :placeholder="field.placeholder" />
  <small class="text-sm text-red-600">{{ errorMessage ? errorMessage : '&nbsp;' }}</small>
</template>
<script setup lang="ts">
import { AutoComplete } from 'primevue';
import { useField } from 'vee-validate';
import { PropType } from 'vue';
import { IFormField } from '@/modules/aiContent/interfaces/IFormField';

const props = defineProps({
  field: {
    type: Object as PropType<IFormField<string[]>>,
    required: true,
  },
});

const { value: inputValue, errorMessage } = useField(props.field?.name, props.field?.rules, {
  initialValue: props.field?.defaultValue || [],
});
</script>
