<template>
  <div v-for="thread in props.threads" :key="thread.threadId" @click="changeThread(thread)" :class="[selectedThread === thread.threadId ? 'bg-gray-100' : '', 'relative my-4 flex cursor-pointer items-center rounded-md p-2 hover:bg-gray-100']">
    <FontAwesomeIcon v-if="!thread.isNew" @click.stop="deleteThread(thread.threadId)" class="absolute -left-1 -top-2 h-4 w-4 cursor-pointer text-green-600 hover:text-green-700" icon="plus"></FontAwesomeIcon>
    <div class="flex-1">
      <p class="text-gray-700">{{ thread.name }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  threads: Array,
  selectedThread: String,
  selectedAssistant: String,
});

const emits = defineEmits(['onChangeThread', 'onDeleteThread']);

const changeThread = (thread) => {
  emits('onChangeThread', thread);
};

const deleteThread = async (threadId: string) => {
  emits('onDeleteThread', threadId);
};
</script>
