export enum ContentTypeEnum {
    GenerateSocialMediaContentIdea = 'GenerateSocialMediaContentIdea',
    GenerateSocialMediaContent = 'GenerateSocialMediaContent',
    GenerateSocialMediaContentHeadline = 'GenerateSocialMediaContentHeadline',
    GenerateSocialMediaContentStrategy = 'GenerateSocialMediaContentStrategy',
    GenerateBlogContentIdea = 'GenerateBlogContentIdea',
    GenerateBlogContent = 'GenerateBlogContent',
    GenerateBlogContentStrategy = 'GenerateBlogContentStrategy',
    OptimizeBlogContent = 'OptimizeBlogContent',
    GenerateNewsletterIdea = 'GenerateNewsletterIdea',
    GenerateNewsletter = 'GenerateNewsletter',
    GenerateEmailContentStrategy = 'GenerateEmailContentStrategy'
}
