<template>
  <div v-for="message in props.messages" :key="message.messageId">
    <div v-if="message.role === EnumsAIMessageRole.Assistant" class="mb-4 w-3/4">
      <div class="grid">
        <div class="mb-3 flex flex-1 gap-2.5">
          <div class="mr-2 flex h-9 w-9 items-center justify-center rounded-full border">
            <img src="/favicon.png" alt="User Avatar" class="h-full min-w-9 object-contain p-1.5" />
          </div>
          <div class="grid">
            <h5 class="pb-1 text-sm font-semibold leading-snug text-gray-900">{{ $t('aiassistant.aiAssistant') }}</h5>
            <div class="">
              <div class="inline-flex items-center justify-start gap-3 rounded-lg bg-gray-100 px-3.5 py-2">
                <h5 class="text-sm font-normal leading-snug text-gray-900" v-html="message?.content.replace(/【.*?】/g, '')"></h5>
              </div>
              <div class="flex justify-end">
                <h6 class="py-1 text-xs font-normal leading-4 text-gray-500"><LocalizedValue :value="{ value: message.createdAt, type: 'Date', format: 'long' }"></LocalizedValue></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- User -->
    <div v-if="message.role === EnumsAIMessageRole.User" class="mb-4 flex cursor-pointer justify-end xl:pl-20">
      <div class="grid">
        <div class="mb-3 flex flex-1 gap-2.5">
          <div class="mr-2 flex h-9 w-9">
            <FontAwesomeIcon icon="fa-user-circle" size="lg"></FontAwesomeIcon>
          </div>
          <div class="grid">
            <h5 class="pb-1 text-sm font-semibold leading-snug text-gray-900">{{ userStore.email }}</h5>
            <div class="">
              <div class="inline-flex items-center justify-start gap-3 rounded-lg bg-main px-3.5 py-2">
                <h5 class="text-sm font-normal leading-snug text-white" v-html="message.content"></h5>
              </div>
              <div class="flex justify-end">
                <h6 class="py-1 text-xs font-normal leading-4 text-gray-500"><LocalizedValue :value="{ value: message.createdAt, type: 'Date', format: 'long' }"></LocalizedValue></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import LocalizedValue from '@/modules/shared/components/LocalizedValue.vue';
import { useUserStore } from '@/modules/shared/stores/user';
import { EnumsAIMessageRole } from '../../../../generated/api';

const userStore = useUserStore();

const props = defineProps({
  messages: Array,
});
</script>
