import { RouteRecordRaw } from 'vue-router';
import { EditModeEnum } from '@/modules/campaigns/enums/EditModeEnum';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import Step from '@/modules/campaigns/pages/Step.vue';
import { DASHBOARD_ROUTES } from '@/modules/dashboard/enums/RoutesEnum';
import { ModulesEnum } from '@/modules/shared/enums/ModulesEnum';
import FullPageLayout from '@/oldLayout/FullPageLayout.vue';
import { EnumsMarketingChannel, EnumsMarketingFormat } from '../../../../generated/api';

function isValidCampaign(channel: string): boolean {
  return Object.values(EnumsMarketingChannel).includes(channel as EnumsMarketingChannel);
}

function isValidFormat(format: string): boolean {
  return Object.values(EnumsMarketingFormat).includes(format as EnumsMarketingFormat);
}

const routes: Array<RouteRecordRaw> = [
  {
    path: 'campaigns',
    meta: {
      module: 'campaigns',
      layout: FullPageLayout,
      quest: false,
      client: true,
    },

    children: [
      {
        path: '',
        component: () => import('@/layout/ClientLayout.vue'),
        children: [
          {
            path: '',
            name: CAMPAIGNS_ROUTES.INDEX,
            component: () => import('@/modules/campaigns/pages/Index.vue'),
            redirect: { name: CAMPAIGNS_ROUTES.FORMATS, params: { channel: EnumsMarketingChannel.Google } },
            children: [
              {
                path: ':channel/formats',
                name: CAMPAIGNS_ROUTES.FORMATS,
                component: () => import('@/modules/campaigns/pages/Formats.vue'),
                props: (route) => ({
                  channel: route.params.channel as EnumsMarketingChannel,
                }),
                beforeEnter: (to, from, next) => {
                  if (isValidCampaign(to.params.channel as EnumsMarketingChannel)) {
                    next();
                  } else {
                    next({ name: DASHBOARD_ROUTES.INDEX });
                  }
                },
              },
            ],
          },
        ],
      },
      {
        path: ':channel/:format/:editMode',
        name: CAMPAIGNS_ROUTES.EDIT,
        component: () => import('@/modules/campaigns/pages/Edit.vue'),
        props: (route) => ({
          editMode: route.params.editMode as EditModeEnum,
          channel: route.params.channel as EnumsMarketingChannel,
          format: route.params.format as EnumsMarketingFormat,
        }),
        children: [
          {
            path: ':step',
            name: CAMPAIGNS_ROUTES.CAMPAIGN_STEP,
            component: Step,
            props: (route) => ({
              channel: route.params.channel as EnumsMarketingChannel,
              format: route.params.format as EnumsMarketingFormat,
              step: route.params.step,
              module: ModulesEnum.CAMPAIGNS,
            }),
            beforeEnter: (to, from, next) => {
              if (isValidCampaign(to.params.channel as EnumsMarketingChannel) && isValidFormat(to.params.format as EnumsMarketingFormat)) {
                next();
              } else {
                next({ name: DASHBOARD_ROUTES.INDEX });
              }
            },
          },
        ],
      },
    ],
  },
];

export default routes;
